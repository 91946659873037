@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');
@import './configuracao/tipografia.css';

* {
  box-sizing: border-box;
}

body {
  background: var(--cor-fundo-cinza);
}

body, 
textarea {
  margin: 0;
  padding: 0;
  color: var(--cor-secundaria);
  font-family: var(--fonte-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* override Material Ui Container padding */
body .MuiContainer-root {
  padding-right: var(--espacamento-medio);
  padding-left: var(--espacamento-medio);
}